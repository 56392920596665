import {ReactNode} from "react";

export default function({ canvas, children }: { canvas: { width: number, height: number }, children: ReactNode }) {
  return <div className="tui-image-editor-container bottom" style={{width: "100%", height: "100%"}}>
    <div className="tui-image-editor-main-container"
         style={{backgroundColor: "#1e1e1e", border: "0px"}}>
      <div className="tui-image-editor-main">
        <div className="tui-image-editor-wrap" style={{bottom: "0px", top: "0px", left: "0px", width: "100%"}}>
          <div className="tui-image-editor-size-wrap">
            <div className="tui-image-editor-align-wrap">
              <div className="tui-image-editor" style={{height: `${canvas.height}px`, width: `${canvas.width}px`, top: "0px", left: "0px"}}>
                <div className="tui-image-editor-canvas-container"
                     style={{width: "100%", height: "100%", position: "relative", userSelect: "none", maxWidth: `${canvas.width}px`, maxHeight: `${canvas.height}px`}}>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
