import { Grid, IconButton, Popover, TextField } from "@radix-ui/themes";
import { MagicWandIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import PromptTextField from "./PromptTextField";

export default function({onApply: onApply}: {onApply: (object: string, replacement: string) => void}) {
  const [object, setObject] = useState("");
  const [replacement, setReplacement] = useState("");

  return (
    <Grid gap="3" columns="1fr 1fr auto" width="400px">
      <PromptTextField placeholder="Object to replace" help="What object need to replace" value={object} setValue={setObject}/>
      <PromptTextField placeholder="Replacement" help="What to paint instead of object" value={replacement} setValue={setReplacement}/>
      <Popover.Close>
        <IconButton size="1"
                    style={{cursor: "var(--cursor-link)"}}
                    title="Replace" disabled={!object || !replacement} onClick={() => onApply(object, replacement)}>
          <MagicWandIcon/>
        </IconButton>
      </Popover.Close>
    </Grid>
  )
}
