import './App.css';

import functions from './functions.json';
import ImageEditor from '@toast-ui/react-image-editor';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, DropdownMenu, Flex, IconButton, Popover, Text, Theme } from '@radix-ui/themes';
import {
  AllSidesIcon,
  ArrowLeftIcon,
  CheckIcon,
  Cross2Icon,
  EraserIcon,
  GroupIcon,
  HeightIcon,
  MoonIcon,
  PaddingIcon,
  PersonIcon,
  SizeIcon,
  SunIcon,
  SymbolIcon,
  UploadIcon,
  WidthIcon,
} from '@radix-ui/react-icons';

import Dropzone from 'react-dropzone';
import ToolButton from './components/ToolButton';
import BrushCanvas from './components/BrushCanvas';
import Processing from './components/Processing';
import { useDebouncedCallback } from 'use-debounce';
import ReplaceObjectTool from './components/tools/ReplaceObjectTool';
import ReplaceBackgroundTool from './components/tools/ReplaceBackgroundTool';
import PromptTool from './components/tools/PromptTool';
import CanvasWrapper from './components/CanvasWrapper';

const instructions =
  'Я редактирую изображение в фоторедакторе с помощью различных функций. ' +
  'Тебе они тоже доступны.\n' +
  'Оформляй свои советы в виде маркдаун ссылок с пустым href. ' +
  'Пользователь может кликнуть на совете, чтобы отправить тебе его текст. ' +
  'Текст каждого совета должен быть меньше 30 символов.\n' +
  'Я буду присылать тебе новую версию изображения всякий раз, когда что-то будет изменено.\n' +
  'Проанализируй изображение прежде чем давать советы.\n' +
  'Давай советы по редактированию, но только релевантные текущему изображению.\n' +
  'Пользователь видит текущее изображение в редакторе, поэтому не пиши ссылку на итоговое изображение.\n' +
  'Все операции выполняй только над текущим изображением.\n' +
  'Твои промпты должны быть конкретными, содержать конкретные цвета, объекты и не ссылаться на другие объекты или цвета.';

const localFeatures = ['rotate', 'resize', 'flipX', 'flipY', 'applyFilter', 'undo', 'crop'] as (
  | keyof tuiImageEditor.ImageEditor
  | string
)[];

let abortController: AbortController | null;
const currentImageMessageId = 'current_image_message';
const resumeMessageId = 'resume_message';
const maskMessageId = 'mask_image_message';

let jayCanvas: typeof JayCanvas | null = null;
const setJayCanvas = (jcInstance: typeof JayCanvas) => {
  jayCanvas = jcInstance;
};

const getCanvasSize = () => {
  const el = document.querySelector('canvas.lower-canvas');
  if (!el) return { width: 512, height: 512 };

  const style = getComputedStyle(el);
  const width = style.getPropertyValue('max-width');
  const height = style.getPropertyValue('max-height');

  return { width: parseInt(width.substring(-2)), height: parseInt(height.substring(-2)) };
};

function App() {
  const editorRef = useRef<(tuiImageEditor.ImageEditor & { getInstance: () => tuiImageEditor.ImageEditor }) | null>(
    null
  );
  const brushRef = useRef();
  const faceImageRef = useRef<HTMLInputElement>(null);

  const [resumed, setResumed] = useState(false);
  const [clicked, setClicked] = useState(false);

  const [imageBase64DataURL, setImageBase64Data] = useState('');
  const [currentImage, setCurrentImage] = useState<{ url: string; loaded?: boolean } | null>(null);
  const [aiTools, setAITools] = useState(false);
  const [brushTool, setBrushTool] = useState('');
  const [processing, setProcessing] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [currentMask, setCurrentMask] = useState('');
  const [createDoodle, setCreateDoodle] = useState(false);

  const addMenuItem = (menu: string, tooltip: string, id: string, icon: string): HTMLElement | undefined => {
    let liElement = document.querySelector<HTMLElement>(`li.${id}`);
    if (liElement) return liElement;

    const ulElement = document.querySelector(`ul.${menu}`);
    if (!ulElement) return;
    liElement = document.createElement('li');
    liElement.setAttribute('tooltip-content', tooltip);
    liElement.classList.add(id, 'tui-image-editor-item', 'normal');

    const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svgElement.classList.add('svg_ic-menu');

    const useDefault = document.createElementNS('http://www.w3.org/2000/svg', 'use');
    useDefault.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `#${icon}`);
    useDefault.classList.add('normal', 'use-default');

    const useActive = document.createElementNS('http://www.w3.org/2000/svg', 'use');
    useActive.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `#${icon}`);
    useActive.classList.add('active', 'use-default');

    const useHover = document.createElementNS('http://www.w3.org/2000/svg', 'use');
    useHover.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `#${icon}`);
    useHover.classList.add('hover', 'use-default');

    svgElement.appendChild(useDefault);
    svgElement.appendChild(useActive);
    svgElement.appendChild(useHover);
    liElement.appendChild(svgElement);
    ulElement.prepend(liElement);

    return liElement;
  };

  const uploadBase64 = async (base64: string, canvas?: typeof JayCanvas) => {
    return await (jayCanvas || canvas)?.uploadFile({ data: base64 });
  };
  const queueUploadFile = async (base64: string, canvas?: typeof JayCanvas) => {
    return await (jayCanvas || canvas)?.queueUploadFile({ data: base64 });
  };

  const process = async (
    tool: string,
    params: Record<string, any> = {},
    assistant = false,
    canvas?: typeof JayCanvas
  ) => {
    if (processing)
      return {
        error: 'Image editor is processing another operation. Please await or cancel.',
      };

    setBrushTool('');
    setCurrentMask('');
    const editor = editorRef.current ? editorRef.current.getInstance() : null;
    if (editor && localFeatures.includes(tool)) {
      try {
        switch (tool) {
          case 'painter_edit_actions':
            return functions;
          case 'applyFilter':
            await editor.applyFilter(params.type);
            break;
          case 'resize':
            await editor.resizeCanvasDimension(params.dimensions);
            break;
          case 'rotate':
            await editor.rotate(params.angle);
            break;
          case 'crop':
            await editor.crop(params.dimensions);
            break;
          case 'ui':
            break;
          default: // @ts-ignore
            await editor[tool]();
        }
        const image = editor.toDataURL();
        setImageBase64Data(image);
        //@ts-ignore method is not typed
        editorRef.current?.getInstance().resetZoom(image);
        const url = await uploadBase64(image, canvas);
        console.log(url);
        setCurrentImage({ url, loaded: true });
        return url;
      } catch (e) {
        console.error(e);
        return { error: e };
      }
    }

    /*let result;
    setProcessing(true);
    abortController = new AbortController();

    if (editor && !params.image) {
      params.image = editor.toDataURL();
    }

    try {
      // const res = await axios.post(`/process/${tool}`, params, { signal: abortController.signal });
      result = params.image;
      setCurrentImage({ url: params.image });
      setCreateDoodle(false);
      if (!assistant && jayCanvas) {
        jayCanvas.saveUserMessage(currentImageMessageId, [
          { text: `Я отредактировал изображение с помощью функции "${tool}", вот результат: ${result}` },
          { image_url: result },
        ]);
      }
    } catch (e) {
      setProcessing(false);
      return { error: e };
    }
    return result;*/
  };

  const callFunction = async (func: { name: string; arguments: Record<string, any> }, canvas?: typeof JayCanvas) => {
    if (processing) {
      return 'Please wait until current operation is complete or cancel it';
    }
    if (func.arguments['name'] === 'inpaint' && !func.arguments['mask_image']) {
      return 'Use replaceObjectSync instead of this tool';
    }
    const result = await process(func.name, func.arguments, true, canvas);
    if (result && result.error) {
      const msg = result.error.message || result.error.msg || result.error;
      if (msg && msg === 'canceled') {
        return 'User has canceled operation';
      } else {
        return typeof msg === 'string' ? msg : 'Unknown error';
      }
    }
    return { result: result || 'error' };
  };

  const cancel = () => {
    if (abortController) {
      abortController.abort();
      abortController = null;
    } else {
      console.error('No abort controller, cannot cancel');
    }
    setProcessing(false);
  };

  const uploadFile = useCallback(async (file: File, canvas?: typeof JayCanvas) => {
    try {
      const image_url = await new Promise<string>(resolve => {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          resolve(e.target?.result as string);
        };
        reader.readAsDataURL(file);
      });

      setImageBase64Data(image_url);
      return image_url;
    } catch (e) {
      console.error(e);
    } finally {
      // setUploading(false);
    }
  }, []);

  const onFilesUpload = (files: File[]) => {
    uploadFile(files[0]);
  };

  const handleFaceFile = (e: any) => {
    if (e.target.files) {
      const file = e.target.files[0];
      e.target.value = null;
      const reader = new FileReader();
      reader.onload = event => {
        window.Jimp.read(event.target?.result as string).then(async img => {
          process('mergeFace', {
            image: '',
            face_image_file: await img.getBase64Async(window.Jimp.AUTO),
          });
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleClicked = useDebouncedCallback((clicked = false) => {
    setClicked(clicked);
  }, 500);

  const restoreState = useCallback(async (state: { currentImageUrl: string }, canvas?: typeof JayCanvas) => {
    setProcessing(true);
    const { data, type, name } = await (jayCanvas || canvas).getFile(state.currentImageUrl);
    await handleNewFile({ data, type, name });
    canvas &&
      canvas.saveUserMessage(currentImageMessageId, [
        { text: `I'm working with this image: ${state.currentImageUrl}` },
        { image_url: state.currentImageUrl },
      ]);
    setCurrentImage({ url: state.currentImageUrl, loaded: true });
    setProcessing(false);
    //@ts-ignore
    editorRef.current?.getInstance().resetZoom();
  }, []);

  const handleNewFile = useCallback(
    async (file: { data: BlobPart; type: any; name: string }, canvas?: typeof JayCanvas, shouldQueue?: boolean) => {
      const blob = new Blob([file.data], { type: file.type });
      const base64 = await uploadFile(new File([blob], file.name, { type: file.type }), JayCanvas || canvas);
      //@ts-ignore
      editorRef.current?.getInstance().loadImageFromURL(base64, file.name);
      if (shouldQueue)
        jayCanvas?.queueUploadFile({
          data: base64,
          type: file.type,
          name: file.name,
        });
      return base64;
    },
    []
  );

  const receiveFileUrl = useCallback(async (url: string) => {
    if (!jayCanvas) setTimeout(() => receiveFileUrl(url), 1000);
    setProcessing(true);
    const { data, type, name } = await jayCanvas.getFile(url);
    await handleNewFile({ data, type, name }, jayCanvas, false);
    jayCanvas &&
      jayCanvas.saveUserMessage(currentImageMessageId, [
        { text: `I'm working with this image: ${url}` },
        { image_url: url },
      ]);
    setCurrentImage({ url: url, loaded: true });
    setProcessing(false);
    //@ts-ignore
    editorRef.current?.getInstance().resetZoom();
  }, []);

  useEffect(() => {
    if (resumed && currentImage) {
      jayCanvas.saveUserMessage(resumeMessageId, `Я редактирую это изображение: ${currentImage.url}`);
    }
  }, [resumed]);

  useEffect(() => {
    if (!window.JayCanvas) return;
    if (!jayCanvas) {
      setProcessing(true);
      window
        .JayCanvas({
          tools: functions,
          restoreState,
          receiveFileUrl,
          handleFile: handleNewFile,
          callFunction,
          cancelFunction: cancel,
          resume: () => setResumed(true),
          pause: () => setResumed(false),
        })
        .then(jcInstance => {
          setJayCanvas(jcInstance);
          setProcessing(false);
        });
      document.onmousedown = () => {
        toggleClicked(false);
      };
      document.onmouseup = () => {
        toggleClicked(true);
      };
    }
  }, []);

  useEffect(() => {
    if (!clicked) return;
    setClicked(false);
    if (processing) return;

    if (editorRef.current && imageBase64DataURL) {
      //@ts-ignore
      const data = editorRef.current?.getInstance().toDataURL();
      if (data !== imageBase64DataURL) {
        setImageBase64Data(data);
        queueUploadFile(data);
        // TODO too much savings
        /*setProcessing(true);
        uploadBase64(data).then(url => {
          setProcessing(false);
          setCurrentImage({ url, loaded: true });
          jayCanvas &&
            jayCanvas.saveUserMessage(currentImageMessageId, [
              { text: `Текущая версия изображения: ${url}` },
              { image_url: url },
            ]);
        });*/
      }
    }
    if (brushRef.current) {
      //@ts-ignore
      brushRef.current?.getMask().then(setCurrentMask);
    }
  }, [jayCanvas, clicked, processing]);

  useEffect(() => {
    if (jayCanvas) {
      if (currentMask) {
        uploadBase64(currentMask).then(url => {
          jayCanvas.saveUserMessage(maskMessageId, [
            { text: createDoodle ? `Я нарисовал набросок: ${url}` : `Я нарисовал маску: ${url}` },
            { image_url: url },
          ]);
        });
      } else {
        jayCanvas.deleteUserMessage(maskMessageId);
      }
    }
  }, [jayCanvas, currentMask, createDoodle]);

  useEffect(() => {
    if (imageBase64DataURL && editorRef.current) {
      // hide unuseful buttons from editor
      document.querySelector('.tie-btn-zoomIn')?.classList.add('d-none');
      document.querySelector('.tie-btn-zoomOut')?.classList.add('d-none');
      document.querySelector('.tie-btn-hand')?.classList.add('d-none');
      // const li = addMenuItem('tui-image-editor-menu', 'AI Tools', 'tie-btn-ai', 'ic-ai');
      // if (li)
      //   li.onclick = () => {
      //     setAITools(true);
      //   };
      const li2 = addMenuItem('tui-image-editor-help-menu.top', 'Download', 'tie-btn-download', 'ic-download');
      if (li2)
        li2.onclick = () => {
          const base64 = editorRef.current?.getInstance().toDataURL();
          if (!base64) return;
          const mimeType = base64.match(/data:([^;]+);base64,/)?.[1];
          if (!mimeType) return;
          const link = document.createElement('a');
          link.download = `image.${mimeType.substring(mimeType.indexOf('/') + 1)}`;
          link.href = base64;
          link.click();
        };
    }
  }, [imageBase64DataURL]);

  useEffect(() => {
    if (!brushTool && jayCanvas) {
      jayCanvas.deleteUserMessage(maskMessageId);
    }
  }, [jayCanvas, brushTool]);

  useEffect(() => {
    if (currentImage?.url && jayCanvas && currentImage.url.startsWith('http')) {
      jayCanvas.updateState({
        currentImageUrl: currentImage.url,
      });
    }
  }, [currentImage?.url]);

  return (
    <Theme appearance='light'>
      <div style={{ height: '100vh' }}>
        <input
          type='file'
          ref={faceImageRef}
          onChange={handleFaceFile}
          style={{ display: 'none' }}
          accept='.jpeg, .jpg, .png'
        />
        {(!window.JayCanvas || (jayCanvas && !imageBase64DataURL && !createDoodle)) && (
          <Dropzone maxFiles={1} noKeyboard accept={{ 'image/*': [] }} onDrop={onFilesUpload}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} style={{ cursor: 'var(--cursor-link)', height: '100%' }}>
                <input {...getInputProps()} />
                <CanvasWrapper canvas={{ width: 500, height: 400 }}>
                  <Flex gap='3' justify='center' align='center' direction='column' width='400px' height='400px'>
                    <UploadIcon />
                    <Text>Загрузить изображение</Text>
                    {/*<Button
                      variant='surface'
                      radius='full'
                      color='gray'
                      mt='3'
                      style={{ cursor: 'pointer' }}
                      onClick={e => {
                        e.stopPropagation();
                        setCreateDoodle(true);
                      }}
                    >
                      Сделать набросок
                    </Button>*/}
                  </Flex>
                </CanvasWrapper>
              </div>
            )}
          </Dropzone>
        )}
        <Box display={processing ? 'none' : 'block'}>
          {imageBase64DataURL && (
            <ImageEditor
              ref={editorRef}
              includeUI={{
                usageStatistics: false,
                menuBarPosition: 'bottom',
                loadImage: {
                  path: imageBase64DataURL,
                  name: 'image',
                },
                uiSize: {
                  width: '100%',
                  height: '100%',
                },
              }}
            />
          )}
        </Box>

        {createDoodle && !processing && (
          <>
            <BrushCanvas canvas={{ width: 512, height: 512 }} brushRadius={6} ref={brushRef} />
            <div className='tui-image-editor-ai-controls'>
              <Flex align='center' justify='center' gap='4' height='100%'>
                <PromptTool
                  placeholder='What to draw'
                  help='Describe desired image to draw'
                  onCancel={() => setCreateDoodle(false)}
                  onApply={async (prompt: string) => {
                    //@ts-ignore
                    const mask = await brushRef.current?.getMask();
                    mask && process('doodle', { prompt, image: mask, similarity: 1 });
                  }}
                />
              </Flex>
            </div>
          </>
        )}

        {(uploading || (window.JayCanvas && !jayCanvas)) && <Processing canvas={{ width: 512, height: 512 }} />}
        {(processing || (currentImage?.url && !imageBase64DataURL)) && (
          <Processing canvas={getCanvasSize()} img={currentImage ? currentImage.url : ''} />
        )}
        {!processing && brushTool && (
          <BrushCanvas
            img={editorRef.current?.getInstance().toDataURL()}
            canvas={getCanvasSize()}
            brushRadius={brushTool === 'inpaint' ? 6 : 10}
            hideGrid
            ref={brushRef}
          />
        )}
        {processing && (
          <div className='tui-image-editor-ai-controls'>
            <Flex align='center' justify='center' gap='4' height='100%'>
              <Button
                size='1'
                radius='full'
                variant='outline'
                color='gray'
                onClick={cancel}
                style={{ cursor: 'var(--cursor-link)' }}
              >
                <Cross2Icon /> Cancel
              </Button>
            </Flex>
          </div>
        )}
        {!processing && brushTool && (
          <div className='tui-image-editor-ai-controls'>
            <Flex align='center' justify='center' gap='4' height='100%'>
              {brushTool === 'erase' && (
                <>
                  <ToolButton tooltip='Cancel' onClick={() => setBrushTool('')}>
                    <Cross2Icon width='24' height='24' />
                  </ToolButton>
                  <ToolButton
                    tooltip='Erase selected'
                    onClick={async () => {
                      //@ts-ignore
                      const mask = await brushRef.current?.getMask();
                      mask && process('cleanup', { mask_file: mask });
                    }}
                  >
                    <CheckIcon width='24' height='24' />
                  </ToolButton>
                </>
              )}
              {brushTool === 'inpaint' && (
                <PromptTool
                  placeholder='Inpaint prompt'
                  help='What to paint instead of selected area'
                  onCancel={() => setBrushTool('')}
                  onApply={async (prompt: string) => {
                    //@ts-ignore
                    const mask = await brushRef.current?.getMask();
                    mask && process('inpaint', { prompt, mask_image: mask });
                  }}
                />
              )}
            </Flex>
          </div>
        )}
        {!processing && aiTools && !brushTool && (
          <div className='tui-image-editor-ai-controls'>
            <Flex align='center' justify='center' gap='4' height='100%'>
              <ToolButton tooltip='Close AI tools' onClick={() => setAITools(false)}>
                <ArrowLeftIcon width='24' height='24' />
              </ToolButton>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger title='Enhance image'>
                  <IconButton size='1' variant='ghost' color='gray' style={{ cursor: 'var(--cursor-link)' }}>
                    <SunIcon width='24' height='24' />
                  </IconButton>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content>
                  <DropdownMenu.Item onClick={() => process('upscale')}>Upscale</DropdownMenu.Item>
                  <DropdownMenu.Item onClick={() => process('color')}>Colorize</DropdownMenu.Item>
                  <DropdownMenu.Item onClick={() => process('enhance')}>Enhance details</DropdownMenu.Item>
                  <DropdownMenu.Item onClick={() => process('restore')}>Restore old photo</DropdownMenu.Item>
                  <DropdownMenu.Item onClick={() => process('lighting')}>Fix lighting</DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
              <Popover.Root>
                <Popover.Trigger title='Replace background'>
                  <IconButton size='1' variant='ghost' color='gray' style={{ cursor: 'var(--cursor-link)' }}>
                    <PaddingIcon width='24' height='24' />
                  </IconButton>
                </Popover.Trigger>
                <Popover.Content>
                  <ReplaceBackgroundTool onApply={(prompt: string) => process('replaceBackground', { prompt })} />
                </Popover.Content>
              </Popover.Root>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger title='Remove...'>
                  <IconButton size='1' variant='ghost' color='gray' style={{ cursor: 'var(--cursor-link)' }}>
                    <EraserIcon width='24' height='24' />
                  </IconButton>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content>
                  <DropdownMenu.Item onClick={() => process('removeBackground')}>Remove background</DropdownMenu.Item>
                  <DropdownMenu.Item onClick={() => process('removeWatermark')}>Remove watermarks</DropdownMenu.Item>
                  <DropdownMenu.Item onClick={() => process('removeText')}>Remove texts</DropdownMenu.Item>
                  <DropdownMenu.Item onClick={() => setBrushTool('erase')}>Remove selection</DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
              <ToolButton tooltip='Inpaint selection' onClick={() => setBrushTool('inpaint')}>
                <GroupIcon width='24' height='24' />
              </ToolButton>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger title='Outpaint image'>
                  <IconButton size='1' variant='ghost' color='gray' style={{ cursor: 'var(--cursor-link)' }}>
                    <AllSidesIcon width='24' height='24' />
                  </IconButton>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content>
                  <DropdownMenu.Item onClick={() => process('outpainting', { aspect_ratio: 'WIDESCREEN' })}>
                    <WidthIcon /> Widescreen
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onClick={() => process('outpainting', { aspect_ratio: 'PORTRAIT' })}>
                    <HeightIcon /> Portrait
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onClick={() => process('outpainting', { aspect_ratio: 'SQUARE' })}>
                    <SizeIcon /> Square
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
              <Popover.Root>
                <Popover.Trigger title='Replace object'>
                  <IconButton size='1' variant='ghost' color='gray' style={{ cursor: 'var(--cursor-link)' }}>
                    <SymbolIcon width='24' height='24' />
                  </IconButton>
                </Popover.Trigger>
                <Popover.Content>
                  <ReplaceObjectTool
                    onApply={(object: string, replacement: string) =>
                      process('replaceObjectSync', { mask_prompt: object, prompt: replacement })
                    }
                  />
                </Popover.Content>
              </Popover.Root>
              <Popover.Root>
                <Popover.Trigger title='Swap face'>
                  <IconButton size='1' variant='ghost' color='gray' style={{ cursor: 'var(--cursor-link)' }}>
                    <PersonIcon width='24' height='24' />
                  </IconButton>
                </Popover.Trigger>
                <Popover.Content>
                  <Box>
                    <Text>Upload photo with face to swap existing one</Text>
                  </Box>
                  <Popover.Close>
                    <Button size='1' style={{ width: '100%' }} mt='3' onClick={() => faceImageRef.current?.click()}>
                      Upload
                    </Button>
                  </Popover.Close>
                </Popover.Content>
              </Popover.Root>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger title='Replace sky'>
                  <IconButton size='1' variant='ghost' color='gray' style={{ cursor: 'var(--cursor-link)' }}>
                    <MoonIcon width='24' height='24' />
                  </IconButton>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content>
                  <DropdownMenu.Item onClick={() => process('replaceSky', { sky: 'sunrise' })}>
                    Sunrise
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onClick={() => process('replaceSky', { sky: 'sunset' })}>Sunset</DropdownMenu.Item>
                  <DropdownMenu.Item onClick={() => process('replaceSky', { sky: 'bluesky' })}>
                    Bluesky
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onClick={() => process('replaceSky', { sky: 'galaxy' })}>Galaxy</DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </Flex>
          </div>
        )}
      </div>
    </Theme>
  );
}

export default App;
