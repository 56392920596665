import { Flex, Spinner, Text } from '@radix-ui/themes';
import CanvasWrapper from './CanvasWrapper';

export default function ({
  canvas,
  placeholder,
  img,
}: {
  canvas: { width: number; height: number };
  placeholder?: string;
  img?: string;
}) {
  return (
    <CanvasWrapper canvas={canvas}>
      <Flex width='100%' height='100%' justify='center' align='center' style={{ backgroundColor: '#f0f0f0' }}>
        <div
          style={{
            position: 'absolute',
            left: '0',
            top: '0',
            width: '100%',
            height: '100%',
            backgroundImage: `url("${img}")`,
            backgroundSize: 'cover',
            filter: 'blur(5px)',
          }}
        ></div>
        <Spinner size='3' />
        {placeholder && <Text ml='2'>{placeholder}</Text>}
      </Flex>
    </CanvasWrapper>
  );
}
