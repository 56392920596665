import ToolButton from "../ToolButton";
import { CheckIcon, Cross2Icon } from "@radix-ui/react-icons";
import { Grid, TextField } from "@radix-ui/themes";
import { useState } from "react";
import PromptTextField from "./PromptTextField";

export default function({ onApply, onCancel, placeholder, help }: { onApply: (prompt: string) => void, onCancel: () => void, placeholder: string, help: string }) {
  const [prompt, setPrompt] = useState("");

  return (
    <Grid align="center" justify="center" gap="4" height="100%" columns="auto 1fr auto" width="500px">
      <ToolButton tooltip="Cancel" onClick={onCancel}>
        <Cross2Icon width="24" height="24"/>
      </ToolButton>
      <PromptTextField placeholder={placeholder} help={help} autoFocus={true} value={prompt} setValue={setPrompt}/>
      <ToolButton tooltip="Inpaint selected" onClick={() => onApply(prompt)} disabled={!prompt}>
        <CheckIcon width="24" height="24"/>
      </ToolButton>
    </Grid>
  )
}
