import CanvasDraw from "react-canvas-draw";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import CanvasWrapper from "./CanvasWrapper";
const Jimp = window.Jimp;

export default forwardRef((props: {img?: string, canvas: {width: number, height: number}, brushRadius?: number, hideGrid?: boolean}, ref) => {
  const [size, setSize] = useState(props.canvas);
  const draw = useRef<CanvasDraw>(null);

  useEffect(() => {
    if (props.img) {
      Jimp.read(props.img)
        .then(image => setSize({
          width: image.bitmap.width, height: image.bitmap.height
        }));
    }
  }, []);

  useImperativeHandle(ref, () => ({
    getMask: async () => {
      let hasMask = false;
      if(!draw.current) return '';
      //@ts-ignore
      const img = await Jimp.read(draw.current.getDataURL());
      img.resize(size.width, size.height, Jimp.RESIZE_NEAREST_NEIGHBOR);
      const result = new Jimp(img.getWidth(), img.getHeight(), 0x000000FF);
      result.composite(img, 0, 0);

      result.scan(0, 0, result.bitmap.width, result.bitmap.height, (idx) => {
        const red = result.bitmap.data[idx];
        const green = result.bitmap.data[idx + 1];
        const blue = result.bitmap.data[idx + 2];

        if (red < 255 || green < 255 || blue < 255) {
          result.bitmap.data[idx] = 0;
          result.bitmap.data[idx + 1] = 0;
          result.bitmap.data[idx + 2] = 0;
        } else {
          hasMask = true;
        }
      });

      return hasMask ? await result.getBase64Async(Jimp.MIME_PNG) : "";
    }
  }));

  return (
    <CanvasWrapper canvas={props.canvas}>
      <CanvasDraw
        ref={draw}
        lazyRadius={0}
        brushRadius={props.brushRadius || 10}
        backgroundColor={"#000"}
        brushColor={"#fff"}
        hideGrid={props.hideGrid}
        immediateLoading={true}
        hideInterface={false}
        imgSrc={props.img}
        canvasHeight={props.canvas.height}
        canvasWidth={props.canvas.width}
      />
    </CanvasWrapper>
  )
})
