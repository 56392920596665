import { Grid, IconButton, Popover } from "@radix-ui/themes";
import { MagicWandIcon } from "@radix-ui/react-icons";
import PromptTextField from "./PromptTextField";
import { useState } from "react";

export default function({ onApply }: { onApply: (prompt: string) => void }) {
  const [value, setValue] = useState("");

  return (
    <Grid gap="3" columns="1fr auto" width="400px">
      <PromptTextField placeholder="Background prompt" help="What to paint instead of current background" value={value} setValue={setValue}/>
      <Popover.Close>
        <IconButton size="1" style={{cursor: "var(--cursor-link)"}} title="Replace"
          disabled={!value} onClick={() => onApply(value)}>
          <MagicWandIcon/>
        </IconButton>
      </Popover.Close>
    </Grid>
  )
}
