import { ReactNode} from "react";
import { IconButton } from "@radix-ui/themes";

export default function({ tooltip, onClick, children, disabled }: {disabled?: boolean, onClick: () => void; tooltip: string; children?: ReactNode }) {
  return (
    <IconButton size="1" variant="ghost" color="gray" title={tooltip}
                     onClick={onClick}
                     style={{cursor: "var(--cursor-link)"}} disabled={disabled}>
      {children}
    </IconButton>
  )
}
