import React, { useEffect, useState } from "react";
import { TextField, Tooltip } from "@radix-ui/themes";
import { InfoCircledIcon, QuestionMarkCircledIcon } from "@radix-ui/react-icons";

const allowedCharactersRegex = /^[a-zA-Z0-9,.\s!-?:]*$/;

export default function({placeholder, value, setValue, autoFocus, help}: {placeholder: string, value: string, setValue: (value: string) => void, autoFocus?: boolean, help?: string}) {
  const [current, setCurrent] = useState(value || "");
  const [accepted, setAccepted] = useState(true);

  useEffect(() => {
    if (!current || allowedCharactersRegex.test(current)) {
      setValue(current ? current.trim() : current);
      setAccepted(true);
    } else {
      setAccepted(false);
    }
  }, [current]);

  return (
    <TextField.Root
      color={accepted ? "gray" : "red"}
      placeholder={placeholder}
      size="1"
      value={current}
      autoFocus={autoFocus}
      onChange={e => setCurrent(e.target.value)}>
      {help && accepted && (
        <TextField.Slot side="right">
          <Tooltip content={help}>
            <QuestionMarkCircledIcon color="gray" cursor={"var(--cursor-link)"}/>
          </Tooltip>
        </TextField.Slot>
      )}
      {!accepted && (
        <TextField.Slot side="right">
          <Tooltip content="Only English please">
            <InfoCircledIcon color="red" cursor={"var(--cursor-link)"}/>
          </Tooltip>
        </TextField.Slot>
      )}
    </TextField.Root>
  );
};
